.box {
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 10%);
  padding: 20px;
}
.faqs-container {
  height: calc(100vh - 300px);
  overflow-y: scroll;
  padding: 0 15px 10px;
}

.faq-container-header {
  background: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
}
.faq-container-header > h3 {
  margin: 0 0 0 10px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #eaeaea;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #eaeaea;
}

.add-faq-btn {
  border-radius: 0 !important;
}

.login-header.inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px;
  background: rgba(22, 22, 22, 0.06);
}

.login-header.inner-wrapper * {
  margin-bottom: 0;
}

.login-header.inner-wrapper > img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  object-position: center center;
  margin-bottom: 15px;
}

.login-header.inner-wrapper h5 {
  font-size: 11px;
  color: #161616;
}

.login-header.inner-wrapper h3 {
  font-size: 13px;
  font-weight: 700;
  color: #161616;
  margin: 3px 0;
}

.login-header.inner-wrapper h6 {
  color: #707070;
  font-size: 10px;
}
h2.auth-heading {
  font-weight: 600;
  font-size: 20px;
  color: #161616;
  margin-bottom: 20px;
}
form.form-horizontal {
  padding: 20px 5px;
}

.lock-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}
.account-pages-auth {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}
.navbar-heading {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
}
.navbar-heading > h6 {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
}
.dashboard-card-wrapper.card-body {
  padding: 35px 30px;
}
.avatar-sm.rounded-circle.bg-primary.align-self-center.mini-stat-icon.dasboard-card {
  height: 8vh;
  width: 8vh;
}
.active-badge {
  background: #d6e9e2 !important;
  color: #32936f !important;
}
.inactive-badge {
  background: #dbdcdd !important;
  color: #495057 !important;
}
.action-btn {
  background: transparent;
  border: none !important;
  padding: 0px 4px !important;
}
.action-icon {
  font-size: 15px;
}
.color-black {
  color: #2a3042 !important;
}
.color-green {
  color: #34c38f !important;
}
.color-red {
  color: #f46a6a !important;
}
.cross-icon {
  display: flex;
  justify-content: space-between;
}
.thumbnail {
  height: 4rem;
  width: 5rem;
  contain: "size";
}
::-ms-reveal {
  display: none;
}
